@tailwind base;
@tailwind components;
@tailwind utilities;

iframe#webpack-dev-server-client-overlay {
  display: none !important;
}
/* body {
  overflow-x: hidden;
} */

.falling-leaves {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  pointer-events: none;
  display: inline-flex;
}

.i {
  position: absolute;
  animation: anim 2.5s ease-in-out infinite;
  transform: translateY(-120%) rotate(0deg);
}

@keyframes anim {
  0% {
    transform: translateY(-120%) rotate(0deg);
  }
  100% {
    transform: translateY(120vh) rotate(360deg);
  }
}

.n1 {
  animation-delay: 0.1s;
}
.n2 {
  animation-delay: 1.2s;
}
.n3 {
  animation-delay: 2s;
}
.n4 {
  animation-delay: 1.4s;
}
.n5 {
  animation-delay: 2.4s;
}
.n6 {
  animation-delay: 0.6s;
}
.n7 {
  animation-delay: 1.8s;
}
.n8 {
  animation-delay: 2.2s;
}
.n9 {
  animation-delay: 1.1s;
}
.n10 {
  animation-delay: 0.4s;
}
.n11 {
  animation-delay: 1.5s;
}
.n12 {
  animation-delay: 1.7s;
}
.n13 {
  animation-delay: 0.5s;
}
.n14 {
  animation-delay: 2.1s;
}
.n15 {
  animation-delay: 2.6s;
}
.n16 {
  animation-delay: 1.8s;
}

/* Media Queries */

/* Small devices (phones) */
@media (max-width: 600px) {
  .i {
    width: 10%;
  }
}

/* Medium devices (tablets) */
@media (min-width: 601px) and (max-width: 1024px) {
  .i {
    width: 6%;
  }
}

/* Large devices (laptops/desktops) */
@media (min-width: 1025px) {
  .i {
    width: 4%;
  }
}
.label-text label,
.label-answer {
  font-size: 19px !important;
}

a {
  text-decoration: none !important;
}

.merienda {
  color: #fff !important;
}

.hover-card {
  box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px,
    rgba(0, 0, 0, 0.22) 0px 10px 10px;
  transition: all 0.5 linear;
}

.katex {
  font-size: 19px !important;
}

.labletext,
th,
h1,
h2,
h3,
h4,
h5,
h6,
a,
button,
.click-here,
.text-table,
.font-upload,
.MuiTableRow-root,
.potta,
label.MuiButtonBase-root {
  font-family: "Potta One", system-ui !important;
  font-weight: 900;
  font-style: normal;
}

.font-upload {
  color: #fff !important;
}
.exam-answer {
  width: 47px;
  height: 47px;
  border: 0px dashed #000;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 10px;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  color: #fff !important;
  background-color: #1976d2;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  font-family: "Potta One", system-ui !important;
}
.exam-answer:last-child {
  margin: 0 0 0 10px;
}
.exam-answer-success {
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  color: rgb(255, 255, 255);
  background-color: rgb(46, 125, 50);
  box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 1px -2px,
    rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px;
  cursor: pointer;
}

.exam-answer:hover {
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  color: rgb(255, 255, 255);
  background-color: rgb(46, 125, 50);
  box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 1px -2px,
    rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px;
  cursor: pointer;
}

.active-answer {
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  color: rgb(255, 255, 255);
  background-color: rgb(46, 125, 50);
  box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 1px -2px,
    rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px;
  cursor: pointer;
}

.user-exam-rank-bs-danger {
  background-color: #dc3545 !important;
}

.time-countdown-danger {
  color: #dc3545 !important;
}

.user-exam-rank-bs-primary {
  background-color: #0d6efd !important;
}

.user-exam-rank-bs-warning {
  background-color: #ffc107 !important;
}

.user-exam-rank-bs-info {
  background-color: #0dcaf0 !important;
}

.user-exam-rank-bs-danger td,
.user-exam-rank-bs-info td,
.user-exam-rank-bs-warning td,
.user-exam-rank-bs-primary td {
  color: #fff !important;
}

.exam-answer-phan2 {
  width: 47px;
  height: 47px;
  border: 0px dashed #000;
  border-radius: 50%;
}

.demo {
  position: absolute;
  left: 50%;
  top: -1%;
  width: 500px;
  height: 140px;
  margin-top: -70px;
  padding: 10px;
  border-radius: 20px;
  transform: translateX(-50%);
}
.demo__colored-blocks {
  overflow: hidden;
  position: absolute;
  left: 50%;
  top: 0;
  width: 500px;
  height: 100%;
  margin-left: -250px;
  padding: 10px;
  border-radius: 20px;
  perspective: 1000px;
  animation: demoAnim 4s ease-in-out infinite, contAnim 4s infinite;
}
.demo__colored-blocks-rotater {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: inherit;
  animation: rotation 1.3s linear infinite;
}
.demo__colored-blocks-inner {
  overflow: hidden;
  position: relative;
  height: 100%;
  background: #32386d;
  border-radius: inherit;
}
.demo__colored-block {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 300%;
  height: 300%;
  transform-origin: 0 0;
}
.demo__colored-block:nth-child(1) {
  transform: rotate(0deg) skewX(-30deg);
  background-color: #fd3359;
}
.demo__colored-block:nth-child(2) {
  transform: rotate(120deg) skewX(-30deg);
  background-color: #f4d302;
}
.demo__colored-block:nth-child(3) {
  transform: rotate(240deg) skewX(-30deg);
  background-color: #21bdff;
}
.demo__inner {
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 100%;
}
.demo__numbers {
  overflow: visible;
  position: absolute;
  left: 50%;
  top: 50%;
  width: 100px;
  height: 100px;
  margin-left: -50px;
  margin-top: -50px;
}
.demo__numbers-path {
  fill: none;
  stroke-width: 10px;
  stroke: #fff;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-dasharray: 0, 518.055065155;
  stroke-dashoffset: 0;
  animation: numAnim 4s ease-in-out infinite;
  opacity: 0;
}
.demo__text {
  position: absolute;
  left: 50%;
  top: 0;
  width: 500px;
  height: 100%;
  margin-left: -250px;
  text-align: center;
  line-height: 140px;
  font-size: 100px;
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 15px;
  transform: translateX(10px);
  animation: hideText 4s infinite;
}
@keyframes contAnim {
  15%,
  100% {
    margin-left: -250px;
    width: 500px;
  }
  25%,
  90% {
    margin-left: -70px;
    width: 140px;
  }
}
@keyframes numAnim {
  15% {
    stroke-dasharray: 0, 518.055065155;
    stroke-dashoffset: 0;
    opacity: 0;
  }
  25%,
  41% {
    opacity: 1;
    stroke-dasharray: 144.4256591797, 518.055065155;
    stroke-dashoffset: -40;
  }
  53%,
  66% {
    stroke-dasharray: 136.0216217041, 518.055065155;
    stroke-dashoffset: -227.238697052;
  }
  76% {
    stroke-dasharray: 113.4751205444, 518.055065155;
    stroke-dashoffset: -445.8995704651;
  }
  88%,
  100% {
    stroke-dasharray: 72.1554946899, 518.055065155;
    stroke-dashoffset: -445.8995704651;
  }
  92% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes rotation {
  to {
    transform: rotate(360deg);
  }
}
@keyframes demoAnim {
  15% {
    border-radius: 20px;
    transform: rotate(0);
  }
  30%,
  43% {
    border-radius: 50%;
    transform: rotate(360deg);
  }
  52%,
  65% {
    border-radius: 0;
    transform: rotate(720deg);
  }
  78%,
  90% {
    border-radius: 50%;
    transform: rotate(1080deg);
  }
  100% {
    border-radius: 20px;
    transform: rotate(1440deg);
  }
}
@keyframes hideText {
  15%,
  100% {
    opacity: 1;
  }
  20%,
  96% {
    opacity: 0;
  }
}

.table-container {
  /* width: 80%; */
  margin: 20px 0;
}

.styled-table {
  width: 100%;
  border-collapse: collapse;
  margin: 25px 0;
  /* font-size: 18px; */
  text-align: left;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
}

.styled-table thead tr {
  background-color: #009879;
  color: #ffffff;
  text-align: left;
  font-weight: bold;
}

.styled-table th,
.styled-table td {
  padding: 12px 15px;
}

.styled-table th {
  padding: 12px 15px;
  color: #ffffff !important;
}

.styled-table tbody tr {
  border-bottom: 1px solid #dddddd;
}

.styled-table tbody tr:nth-of-type(even) {
  background-color: #f3f3f3;
}

.styled-table tbody tr:last-of-type {
  border-bottom: 2px solid #009879;
}

.styled-table tbody tr.active-row {
  font-weight: bold;
  color: #009879;
}

.divider {
  display: flex;
  align-items: center;
  text-align: center;
}
.divider::before,
.divider::after {
  content: "";
  flex: 1;
  border-bottom: 2px dashed black;
}
.divider:not(:empty)::before {
  margin-right: 0.25em;
}
.divider:not(:empty)::after {
  margin-left: 0.25em;
}

.upload-container {
  width: 100%;
  margin: 0 auto;
  height: 250px;
  border: 2px dashed #ccc;
  border-radius: 10px;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  transition: border-color 0.3s;
  margin-bottom: 3rem;
}

.upload-container:hover {
  border-color: #333;
}

.upload-container input[type="file"] {
  display: none;
}

.upload-container label {
  font-size: 14px;
  color: #666;
  cursor: pointer;
  padding: 10px 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #fff;
  transition: background-color 0.3s, color 0.3s, border-color 0.3s;
}

.upload-container label:hover {
  background-color: #333;
  color: #fff;
  border-color: #333;
}

.upload-icon {
  font-size: 50px;
  color: #ccc;
  margin-bottom: 10px;
}

.ruby span {
  display: inline-block;
  vertical-align: middle;
}

.panel,
.panel-hd {
  background: rgba(0, 0, 0, 0.3);
  box-shadow: 0 0 30px 10px rgba(0, 0, 0, 0.3);
  border-radius: 25px;
  transition: 0.2s cubic-bezier(0.55, 0.085, 0.68, 0.53);
  /* transform: rotateX(-90deg); */
  width: 36.8%;
  display: none;
  position: fixed;
  z-index: 9999999;
  left: 512px;
  top: 265px;
}
.panel.is--open,
.panel-hd.is--open {
  transform: rotateX(0deg);
}
.panel__content {
  padding-bottom: 2em;
  background: #fff;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  color: #5a5a5a;
  text-align: center;
}
.panel__content h4 {
  padding: 1em 0;
  border-bottom: 2px solid #ededed;
  color: #865fdf;
  font-size: 2em;
  font-weight: 700;
  text-transform: uppercase;
}
.panel__content h2 {
  padding: 0.5em 1.5em;
  font-size: 3em;
  font-weight: 300;
}
.panel__content p {
  margin: auto;
  width: 55%;
  font-size: 1em;
  font-weight: 300;
  line-height: 1.4;
}
.panel__flaps {
  display: flex;
  perspective: 1000px;
  transform-style: preserve-3d;
}
.flap {
  height: 100px;
  background: #865fdf;
}
.flap__btn {
  width: calc((100% - 212px) / 2);
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background: #8f69e8;
  color: white;
  cursor: pointer;
  font-size: 2.25em;
  font-weight: 700;
  text-decoration: none;
  z-index: 1;
  transition: 0.2s ease;
  transform: translate3d(0, 0, 0);
  transform-origin: top center;
}
.flap__btn:hover,
.flap__btn:focus {
  background: #865fdf;
  outline: none;
  transform: rotateX(-25deg) translate3d(0, 0, 0);
  text-decoration: none;
  color: white;
}
.outer {
  width: 106px;
}
.flap--left {
  border-bottom-left-radius: 25px;
  transform-origin: top right;
}
.flap--right {
  border-bottom-right-radius: 25px;
  transform-origin: top left;
}
.is--open .outer {
  animation: swing 1.5s 0.2s ease;
}
.is--open .flap__btn:first-of-type {
  animation: swing 1.5s 0.3s ease;
}
.is--open .flap__btn:last-of-type {
  animation: swing 1.5s 0.4s ease;
}
@keyframes swing {
  10% {
    transform: rotateX(25deg);
  }
  50% {
    transform: rotateX(-15deg);
  }
  75% {
    transform: rotateX(5deg);
  }
  90% {
    transform: rotateX(-2.5deg);
  }
  100% {
    transform: rotateX(0deg);
  }
}
.replay {
  position: absolute;
  top: 50%;
  left: 50%;
  padding: 0.5em;
  background: #865fdf;
  border-radius: 3px;
  color: white;
  cursor: pointer;
  opacity: 0;
  pointer-events: none;
  text-decoration: none;
  transition: all 0.3s;
  transform: translate(-50%, -50%);
}
.replay:hover,
.replay:focus {
  background: #8f69e8;
  outline: none;
  box-shadow: 0 0 25px #865fdf;
}
.replay:active {
  transform: translate(-50%, -50%) scale(0.9);
}
.replay.is--active {
  opacity: 1;
  pointer-events: initial;
}

.question-label {
  position: absolute;
  top: -20px;
  left: 20px;
  --tw-text-opacity: 1 !important;
  background: rgb(5 150 105 / var(--tw-text-opacity)) !important;
  font-weight: bold;
  color: #ffffff;
  padding: 5px !important;
}

.question-label::before {
  content: "";
  position: absolute;
  top: 50%;
  left: -20px;
  right: -20px;
  transform: translateY(-50%);
  z-index: -1;
}

.ruby {
  display: inline-block;
  vertical-align: middle;
}

table.table-contaiter {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  border: 1px solid #000000;
}
.table-contaiter th,
.table-contaiter td {
  border: 1px solid #000000;
  padding: 8px;
}
.table-contaiter th {
  background-color: #009879;
  color: #ffffff;
}
.table-contaiter th.question {
  text-align: center;
  width: 50px;
}

.table-contaiter td.question {
  text-align: center;
}
.table-contaiter th.statement {
  text-align: left;
}
.table-contaiter td.correct,
.table-contaiter td.wrong {
  width: 50px;
  text-align: center;
}

.rank {
  width: 15%;
}

.name {
  width: 20%;
}

.school {
  width: 20%;
}

.score {
  width: 10%;
}

.exam-time {
  width: 15%;
}

.submit-time {
  width: 20%;
}

:root {
  --path: #2f3545;
  --dot: #5628ee;
  --duration: 3s;
}

.loader {
  width: 44px;
  height: 44px;
  position: relative;
  display: inline-block;
  margin: 0 16px;
}

.loader:before {
  content: "";
  width: 6px;
  height: 6px;
  border-radius: 50%;
  position: absolute;
  display: block;
  background: var(--dot);
  top: 37px;
  left: 19px;
  transform: translate(-18px, -18px);
  animation: dotRect var(--duration) cubic-bezier(0.785, 0.135, 0.15, 0.86)
    infinite;
}

.loader svg {
  display: block;
  width: 100%;
  height: 100%;
}

.loader svg rect,
.loader svg polygon,
.loader svg circle {
  fill: none;
  stroke: var(--path);
  stroke-width: 10px;
  stroke-linejoin: round;
  stroke-linecap: round;
}

.loader svg polygon {
  stroke-dasharray: 145 76 145 76;
  stroke-dashoffset: 0;
  animation: pathTriangle var(--duration) cubic-bezier(0.785, 0.135, 0.15, 0.86)
    infinite;
}

.loader svg rect {
  stroke-dasharray: 192 64 192 64;
  stroke-dashoffset: 0;
  animation: pathRect 3s cubic-bezier(0.785, 0.135, 0.15, 0.86) infinite;
}

.loader svg circle {
  stroke-dasharray: 150 50 150 50;
  stroke-dashoffset: 75;
  animation: pathCircle var(--duration) cubic-bezier(0.785, 0.135, 0.15, 0.86)
    infinite;
}

.loader.triangle {
  width: 48px;
}

.loader.triangle:before {
  left: 21px;
  transform: translate(-10px, -18px);
  animation: dotTriangle var(--duration) cubic-bezier(0.785, 0.135, 0.15, 0.86)
    infinite;
}

@keyframes pathTriangle {
  33% {
    stroke-dashoffset: 74;
  }
  66% {
    stroke-dashoffset: 147;
  }
  100% {
    stroke-dashoffset: 221;
  }
}

@keyframes dotTriangle {
  33% {
    transform: translate(0, 0);
  }
  66% {
    transform: translate(10px, -18px);
  }
  100% {
    transform: translate(-10px, -18px);
  }
}

@keyframes pathRect {
  25% {
    stroke-dashoffset: 64;
  }
  50% {
    stroke-dashoffset: 128;
  }
  75% {
    stroke-dashoffset: 192;
  }
  100% {
    stroke-dashoffset: 256;
  }
}

@keyframes dotRect {
  25% {
    transform: translate(0, 0);
  }
  50% {
    transform: translate(18px, -18px);
  }
  75% {
    transform: translate(0, -36px);
  }
  100% {
    transform: translate(-18px, -18px);
  }
}

@keyframes pathCircle {
  25% {
    stroke-dashoffset: 125;
  }
  50% {
    stroke-dashoffset: 175;
  }
  75% {
    stroke-dashoffset: 225;
  }
  100% {
    stroke-dashoffset: 275;
  }
}

html {
  -webkit-font-smoothing: antialiased;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

body {
  min-height: 100vh;
  background: #f5f9ff !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dribbble {
  position: fixed;
  display: block;
  right: 20px;
  bottom: 20px;
}

.dribbble img {
  display: block;
  height: 28px;
}

table.table-view-answer {
  width: 100%;
  border-collapse: collapse;
  margin: 20px 0;
  table-layout: fixed;
}

.table-view-answer th,
.table-view-answer td {
  border: 1px solid black;
  padding: 8px;
  text-align: center;
}

.table-view-answer th,
.tr-phan2 td,
.tr-phan3 td,
td.tr-select {
  background-color: #009879;
  color: #ffffff !important;
  font-family: "Potta One", system-ui !important;
  font-weight: 600;
  font-style: normal;
  font-size: 15px !important;
}

.table-view-answer td div {
  margin: 5px 0;
}

.scaptcha-anchor-container {
  width: 50% !important;
}

/* Profile Card Styles */
.profile-card {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  width: 350px;
  text-align: center;
  transition: transform 0.3s ease;
}

.profile-card:hover {
  transform: translateY(-10px);
}

/* Profile Header */
.profile-header {
  background-color: #2c3e50;
  padding: 30px 20px;
}

.profile-avatar {
  border-radius: 50%;
  border: 5px solid #fff;
  width: 100px;
  height: 100px;
}

.profile-name {
  color: #fff;
  font-size: 24px;
  margin: 10px 0 5px;
}

.profile-title {
  color: #ecf0f1;
  font-size: 14px;
  margin-bottom: 0;
}

/* Profile Info */
.profile-info {
  padding: 20px;
  text-align: left;
  font-size: 14px;
  line-height: 1.6;
  color: #333;
}

.profile-info p {
  margin: 10px 0;
}

/* Profile Footer */
.profile-footer {
  background-color: #ecf0f1;
  padding: 15px;
  display: flex;
  justify-content: space-around;
}

.btn-pi {
  background-color: #2c3e50;
  border: none;
  border-radius: 20px;
  color: #fff;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s ease;
}

.btn:hover {
  background-color: #34495e;
}

.dropzone {
  min-height: 100px;
  border: 2px dashed #ccc;
  padding: 10px;
  margin-top: 10px;
}
.draggable {
  cursor: grab;
}
.question-content div {
  font-family: "Palatino Linotype", Arial, sans-serif !important;
  font-weight: 400 !important;
  font-weight: 400 !important;
  font-style: normal;
  font-size: 1.1rem !important;
  color: black !important;
  line-height: 1.5;
  letter-spacing: 0.00938em;
}

.timer {
  font-size: 18px;
  margin-bottom: 20px;
  position: relative;
}

.progress-bar {
  background: #e0e0e0;
  border-radius: 20px;
  height: 20px;
  width: 100%;
  margin-bottom: 10px;
  overflow: hidden;
  position: relative;
}

.progress {
  background: #6a11cb;
  height: 100%;
  transition: width 0.5s linear;
}

.timer span {
  position: absolute;
  top: 25px;
  left: 50%;
  transform: translateX(-50%);
  font-weight: bold;
}

.quiz-box {
  background: #fff;
  color: #333;
  border-radius: 10px;
  width: 90%;
  max-width: 500px;
  margin: 20px auto;
  padding: 20px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

.question {
  margin-bottom: 20px;
}

.options {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.option {
  background: #f1f1f1;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.option:hover {
  background: #d1e7ff;
}

.option.selected {
  background: #4caf50;
  color: #fff;
}

.submit-button {
  margin-top: 20px;
  padding: 10px 20px;
  background: #6a11cb;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: all 0.2s ease-in-out;
}

.submit-button:hover {
  background: #2575fc;
}

.short-answer input {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-size: 16px;
}

.short-answer input:focus {
  outline: none;
  border-color: #6a11cb;
  box-shadow: 0 0 5px rgba(106, 17, 203, 0.5);
}

.min-h-max-content {
  /* min-height: -webkit-max-content !important; */
  /* min-height: -moz-max-content !important; */
  min-height: max-content !important;
}
